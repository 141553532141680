$( $ => {
  const header = $( 'header' );
  const body = $( 'body' );
  const headerMainSubmenu = header.find( '#mega-menu-main-menu > li.mega-menu-item.mega-menu-item-has-children' );
  const whiteHeaderHelperClass = $( '.bs-section--add-white-header-to-page' );

  const scrollClass = () => {
    if ( $( window ).scrollTop() >= 50 ) {
      header.addClass( 'page-scrolled' ).removeClass( 'page-at-top' );
      body.addClass( 'page-scrolled' );
    } else {
      header.removeClass( 'page-scrolled' ).addClass( 'page-at-top' );
      body.removeClass( 'page-scrolled' );
    }
  };

  headerMainSubmenu.each( ( index, el ) => {
    const element = $( el );
    const headerMainLink = element.find( '.mega-menu-row li.mega-menu-column:not(.mega-post-column)' );
    const subMenuContentWrapper = $( '<div class="mega-menu-column-wrapper"></div>' );
    headerMainLink.wrapAll( subMenuContentWrapper );
  } );

  if ( whiteHeaderHelperClass.length > 0 ){
    body.addClass( 'white-header' );
  }

  $( window ).bind( 'load scroll', () => {
    scrollClass();
  } );
} );

( ( $ ) => {
  require( 'slick-carousel-latest' );
  const eventsSlider = $( '.bs-section--events-posts .bs-slider-content' );
  const pastEvents = document.querySelector( '.bs-section--events-posts .bs-posts__normal-row' );
  const config = { childList: true, subtree: true };
  const callback = function( mutationsList ) {
    for( const mutation of mutationsList ) {
      if ( 
        mutation.type === 'childList' &&
				eventsSlider.hasClass( 'slick-initialized' ) ) {
        eventsSlider.slick( 'refresh' );
      }
    }
  };
  const observer = new MutationObserver( callback );
  if( pastEvents ) {
    observer.observe( pastEvents, config );
  }
} )( jQuery );
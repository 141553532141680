$( ( $ ) => {
  const duration = 600;
  const footer = $( 'footer' );

  const footerItemChild = footer.find(
    '.navbar-nav > .menu-item-has-children'
  );

  footerItemChild.on( 'click', ( e ) => {
    if ( window.matchMedia( '(max-width: 991.98px)' ).matches ) {
      const $this = $( e.target );
      const isExpanded = $this.hasClass( 'footer-expanded' );
      footer
        .find( '.footer-expanded' )
        .removeClass( 'footer-expanded' )
        .find( '>ul.sub-menu' )
        .slideUp( duration );
      if ( isExpanded ) {
        $this.find( '>ul.sub-menu' ).slideUp( duration );
      } else {
        $this.addClass( 'footer-expanded' );
        $this.find( '>ul.sub-menu' ).slideDown( duration );
      }
    }
  } );

} );
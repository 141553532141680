$( document ).ready( function () {
  const menuSearchIcon = document.querySelector( '.menu-search-icon' );
  const menuTitle = menuSearchIcon.querySelector( '.mega-menu-title' );
  const searchClose = menuSearchIcon.querySelector( '.search-close' );
  const searchInput = menuSearchIcon.querySelector( '.search-input' );
  const header = document.querySelector( 'header' );
  const body = document.body;
  const originalPlaceholder = searchInput.getAttribute( 'data-placeholder' );


  function addActiveSearchClass() {
    const megaDescriptionGroup = menuSearchIcon.querySelector( '.mega-description-group' );
    megaDescriptionGroup.classList.add( 'active-search' );
    header.classList.add( 'header-active-search' );
    searchInput.focus();
    body.style.overflow = 'hidden';
  }

  function removeActiveSearchClass() {
    const megaDescriptionGroup = menuSearchIcon.querySelector( '.mega-description-group' );
    megaDescriptionGroup.classList.remove( 'active-search' );
    header.classList.remove( 'header-active-search' );
    body.style.overflow = '';
  }

  menuTitle.addEventListener( 'click', addActiveSearchClass );
  searchClose.addEventListener( 'click', removeActiveSearchClass );

  const observer = new MutationObserver( function ( mutationsList ) {
    for ( const mutation of mutationsList ) {
      if ( mutation.type === 'attributes' && mutation.attributeName === 'placeholder' ) {
        const currentPlaceholder = searchInput.getAttribute( 'placeholder' );
        if ( currentPlaceholder === '' ) {
          searchInput.setAttribute( 'placeholder', originalPlaceholder );
        }
      }
    }
  } );

  observer.observe( searchInput, { attributes: true } );

  if ( searchInput.getAttribute( 'placeholder' ) === '' ) {
    searchInput.setAttribute( 'placeholder', originalPlaceholder );
  }
} );

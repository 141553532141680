( ( $ ) => {
  const notifyTitle = $( '.notify-bar__description-link > p' );
  const notifyBar = $( '.notify-bar__title' );
  if ( notifyTitle?.prop( 'scrollWidth' ) > notifyBar?.width() ) {
    const clonedTitle = notifyTitle.clone();
    notifyTitle.parent().append( clonedTitle );
    $( '.notify-bar__description-link > p' ).wrapAll( '<div class="notify-bar__title-wrapper"></div>' );
    notifyBar.addClass( 'start-animation' );
  }

} )( jQuery );

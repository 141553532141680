const pauseVideos = ( videos ) => {
  if ( videos.length > 0 ) {
    videos[0].pause();
  }
};

const playVideos = ( videos ) => {
  if ( videos.length > 0 ) {
    const isAutoPlay = jQuery( videos ).eq( 0 ).attr( 'autoplay' );
    if( isAutoPlay )
      videos[0].play();
  }
};

const stopIframes = ( iframes ) => {
  if ( iframes.length > 0 ) {
    const targetOrigin = 'https://www.youtube.com/'; 
    iframes[0].contentWindow.postMessage( '{"event":"command","func":"stopVideo","args":""}', targetOrigin );
  }
};

const handleIntersection = ( entry, videos, iframes ) => {
  if ( !entry.isIntersecting ) {
    pauseVideos( videos );
    stopIframes( iframes );
  } else {
    playVideos( videos );
  }
};

$( () => {
  const bottomRootMargin =
    $( window ).height() > $( window ).width() ? '-8%' : '-16%';

  $.fn.observeVideo = function () {
    $( this ).each( ( i, element ) => {
      const videos = $( element ).find( 'video' );
      const iframes = $( element ).find( 'iframe' );
      if ( videos.length > 0 || iframes.length > 0 ) {
        const videoObserver = new IntersectionObserver(
          ( entries ) => {
            const entry = entries[0];
            handleIntersection( entry, videos, iframes );
          },
          {
            root: null,
            rootMargin: '0px 0px ' + bottomRootMargin + ' 0px',
          }
        );
        videoObserver.observe( element );
      }
    } );
  };

  $.fn.observeElements = function () {
    $( this ).each( ( i, element ) => {
      const observer = new IntersectionObserver(
        ( entries ) => {
          const entry = entries[0];
          if ( entry.isIntersecting ) {
            element.classList.add( 'bs-appeared' );
            observer.disconnect();
          }
        },
        {
          root: null,
          rootMargin: '0px 0px ' + bottomRootMargin + ' 0px',
        }
      );
      observer.observe( element );
    } );
  };

  $( '.bs-section--observe, .bs-div--observe, .bs-blurb--observe, .bs-slider--observe' ).observeElements();
  $( '.bs-media-element--video-observe' ).observeVideo();
} );
( ( $ ) => {
  $( () => {
    const section = $( '.bs-section--common-image-content-scroll-section' ),
      items = $( section ).find( '.bs-column--left > .bs-div' ),
      images = $( section ).find( '.bs-column--right .bs-div--main .bs-div--element-wrapper' ),
      mediaEle = $( section ).find( '.bs-column--right .bs-div--element-wrapper' ),
      appendEl = $( section ).find( '.bs-column--left > .bs-div > .bs-div__inner' );

    // append media el
    appendEl.each( ( index, el ) => {
      $( mediaEle[index] ).clone( true ).insertBefore( el );
    } );

    $( items ).each( ( index, item ) => {
      $( item ).attr( 'data-id', index );
      if ( index === 0 ) {
        $( images[index] ).addClass( 'active' );
      }
    } );

    const activeFunc = ( contents ) => {
      if ( $( section ).length === 1 ) {
        contents.each( ( index, item ) => {
          const menuInf = item.getBoundingClientRect();
          if ( 400 >= menuInf.top ) {
            $( images ).removeClass( 'active' );
            $( images[index] ).addClass( 'active' );
          }
        } );
      }
    };

    activeFunc( items );

    $( window ).on( 'scroll', () => {
      activeFunc( items );
    } );
  } );
} )( jQuery );

class AlertBoxes {
  constructor ( id, alertBox ) {
    this.alertBox = alertBox;
    this.alertBoxId = id;
    this.hH = $( 'header' ).outerHeight( true );
  }

  cloneAlertBox() {
    const popupBox = this.alertBox.clone()
      .addClass( 'bs-div--free-alerts-box--popup' )
      .attr( 'data-id', `popoup-${this.alertBoxId}` );
    this.alertBox
      .attr( 'data-id', `popoup-${this.alertBoxId}` )
      .parent().append( popupBox );
  }

  openPopupBox( alertBox ) {
    $( '.bs-column--free-alerts-boxes' ).addClass( 'bs-column--free-alerts-boxes-open' );
    const id = alertBox.attr( 'data-id' );
    $( `.bs-div--free-alerts-box--popup[data-id="${id}"]` ).addClass( 'active' );
    if( $( window ).width() < 768 ) {
      const parentOffsetTop = alertBox.parent().offset().top;
      window.scrollTo( { top: parentOffsetTop - this.hH - 20, behavior: 'smooth'} );
    }
  }

  init() {
    this.cloneAlertBox();
    this.alertBox.on( 'click', () => {
      this.openPopupBox( this.alertBox );
    } );
  }
}
$( $ => {
  $( '.bs-div--free-alerts-box' ).each( ( i, alertBox ) => {
    const alertBoxPopup = new AlertBoxes( i, $( alertBox ) );
    alertBoxPopup.init();
  } );
  const alertBoxesWrapper = $( '.bs-column--free-alerts-boxes' );
  if( alertBoxesWrapper.length > 0 ) {
    alertBoxesWrapper.append( '<span class="alert-box-popup-close"></span>' );
    alertBoxesWrapper.find( '.alert-box-popup-close' ).on( 'click', () => {
      $( '.bs-column--free-alerts-boxes' ).removeClass( 'bs-column--free-alerts-boxes-open' );
      $( '.bs-div--free-alerts-box--popup' ).removeClass( 'active' );
    } );
  }
} );
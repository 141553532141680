$( () => {
  const menuWrapper = $( '.bs-section--careers-inner-menu' );
  let adminBarHeight = 0;

  if ( $( '#wpadminbar' ).length ) {
    adminBarHeight = parseInt( $( '#wpadminbar' ).outerHeight() );
  }

  const scrollFunc = () => {
    const observer = new IntersectionObserver( ( entries ) => {
      entries.forEach( ( entry ) => {
        const id = entry.target.getAttribute( 'id' );
        if ( entry.isIntersecting ) {
          $( `.bs-section--careers-inner-menu a[href='#${id}']` )
            .parent( '.wp-block-navigation-item' ).addClass( 'active' );
        } else {
          $( `.bs-section--careers-inner-menu a[href='#${id}']` )
            .parent( '.wp-block-navigation-item' ).removeClass( 'active' );
        }
      } );
    }, { rootMargin: '-50% 0px', thresholds: 0.1 } );

    $( 'section' ).each( ( index, element ) => {
      observer.observe( element );
    } );
  };

  scrollFunc();


  $( window ).on( {
    load: () => {
      if ( $( window.location.hash ).length > 0 ) {
        const offsetPosition =
          $( window.location.hash ).offset().top -
          $( 'header .container' ).height() -
          $( '.bs-section--careers-inner-menu' ).outerHeight() -
          adminBarHeight;
        $( 'html, body' ).stop().animate(
          {
            scrollTop: offsetPosition,
          },
          50
        );
      }
    },
    resize: () => {
      const debounce = ( func, delay ) => {
        let timer;
        return () => {
          clearTimeout( timer );
          timer = setTimeout( () => {
            func(); 
          }, delay );
        };
      };
      debounce( scrollFunc, 100 ); 
    },
  } );
  

  menuWrapper.find( 'a[href^="#"]' ).each( ( index, element ) => {
    const $this = $( element );
    $this.on( 'click', ( e ) => {
      e.preventDefault();
      const target = $this.attr( 'href' );
      if ( $( target ).length ) {
        const offsetPosition =
          $( target ).offset().top -
          $( 'header .container' ).height() -
          $( '.bs-section--careers-inner-menu' ).outerHeight() -
          adminBarHeight + 5;
        $( 'html, body' ).stop().animate(
          {
            scrollTop: offsetPosition,
          },
          50
        );
      }
    } );
  } );
} );

/* eslint-disable */
jQuery( document ).ready( function( $ ) {
    let banner =  document.querySelector('#onetrust-banner-sdk');
    let interval;
    interval = setInterval(() => {
        if (banner) {
            clearInterval(interval);
            banner.classList.add('ot-sdk-show');
        }
        banner =  document.querySelector('#onetrust-banner-sdk');
    }, 500);
});
/* eslint-enable */
$( $ => {
  if ( $( '#current-job-openings' ).length == 0 ) {
    return;
  }

  const odd_even = () => {
    const jobs = $( '.career__type-row .bs-posts__column ' );
    jobs.each( ( i, item ) => {
      if ( i % 2 == 1 ) {
        $( item ).addClass( 'even' );
      } else {
        $( item ).addClass( 'odd' );
      }
    } );
  };

  odd_even();

  $( document ).on( 'facetwp-loaded', () => {
    odd_even();
  } );
} );

/* eslint-disable no-undef */
/* eslint-env jquery FWP */
$( document ).on( 'facetwp-loaded', () => {
  if ( FWP == undefined ) {
    return;
  }
  $( '[data-department-title]' ).each( ( dI, department ) => {
    const title = $( department ).data( 'department-title' );
    const elementsByDepartmentTitle = Array.from( $( '[data-department-title="' + title + '"]' ) );
    const firstDepartmentElement = elementsByDepartmentTitle.shift();
    if ( firstDepartmentElement && elementsByDepartmentTitle.length ) {
      for ( const elementByDepartmentTitle of elementsByDepartmentTitle ) {
        $( firstDepartmentElement ).find( '.career__list' )
          .append(
            $( elementByDepartmentTitle ).find( '.career__list' ).html()
          );
        $( elementByDepartmentTitle ).remove();
      }
    }
  } );
} );

( ( $ ) => {
  $.fn.marqueePlugin = function () {
    const marqueeRows = this;
    let minLogoCount = 1000;
    marqueeRows.each( ( ind, ele ) => {
      const logoCount = $( ele ).find( '.bs-blurb--moving-blurb' ).length;
      if ( minLogoCount > logoCount ) {
        minLogoCount = logoCount;
      }
    } );
    marqueeRows.each( ( ind, ele ) => {
      const marqueeRow = $( ele );
      const marqueeItems = marqueeRow.find( '.bs-blurb--moving-blurb' );
      marqueeRow.find( '>.bs-div__inner' )
        .append( marqueeItems.clone() )
        .append( marqueeItems.clone() )
        .append( marqueeItems.clone() )
        .addClass( 'start-marquee' );

      marqueeRow.on( 'mouseover mouseleave', '.bs-blurb--moving-blurb', function ( e ) {
        const targetElem = $( this );
        const targetElemLink = targetElem.find( 'a' ).length;
        if ( e.type === 'mouseover' && targetElemLink ) {
          targetElem.parent().addClass( 'stop-marquee' );
        } else if ( e.type === 'mouseleave' ) {
          targetElem.parent().removeClass( 'stop-marquee' );
        }
      } );
    } );
  };
  $( '.bs-div--moving-blurb-wrapper' ).marqueePlugin();
} )( jQuery );


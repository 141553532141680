( $ => {
  const scrollBtn = $( '.bs-section--careers-banner .bs-pro-button' ),
    menuBar = $( '.bs-section--careers-inner-menu' ),
    adminBar = $( 'body.admin-bar' );
  if ( scrollBtn.length > 0 ) {
    scrollBtn.on( 'click', e => {
      const $this = $( '#open-positions' );
      const adminBarHeight = ( adminBar.length > 0 ) ? 32 : 0;
      const menuBarHeight = ( menuBar.length > 0 ) ? menuBar.outerHeight( true ) : 0;
      const topPosition = $( $this ).offset().top - ( 60 + adminBarHeight + menuBarHeight );
      e.preventDefault();
      $( 'html, body' ).animate(
        {
          scrollTop: topPosition,
        },
        500
      );
    } );
  }
} )( jQuery );

class PressInnerAudioPlayer {
  constructor( wrapperDiv ) {
    this.wrapperDiv = wrapperDiv;
    this.wrapperDivInner = wrapperDiv.find( '>div' );
    this.contentDiv = wrapperDiv.find( '.bs-div' );
    this.audio = wrapperDiv.find( 'audio' ).get( 0 );
    this.playbackSpeeds = [0.5, 0.75, 1, 1.25, 1.5, 2];
  }

  createDom() {
    const audioWrapper = $( '<div class="audio-player"></div>' );
    this.progressBar = $( '<div class="audio-player__progress-bar"></div>' );
    this.progressBarInner = $( '<div class="audio-player__progress-bar-inner"></div>' );
    this.volumeBar = $( '<div class="audio-player__volume-bar position-absolute"></div>' );
    this.volumeBarInner = $( '<div class="audio-player__volume-bar-inner"></div>' );
    this.volumeButton = $( '<div class="audio-player__volume-button"></div>' );
    this.volumeButtonIcon = $( '<div class="audio-player__volume-button-icon"></div>' );
    this.playButton = $( '<div class="audio-player__play-button" action="play"></div>' );
    this.progressBar.append( this.progressBarInner );
    this.volumeBar.append( this.volumeBarInner );
    this.volumeButton.append( this.volumeBar, this.volumeButtonIcon );
    audioWrapper.append( this.playButton, this.progressBar, this.volumeButton );
    this.contentDiv.append( audioWrapper );

    const bottomButtonsWrapper = $( '<div class="audio-player__bottom-wrapper"></div>' );
    this.downloadButton = $( `<a 
                                class="audio-player__download-button" 
                                href="${this.audio.src}" 
                                title="Download" 
                                download
																target="_blank"
                                ></a>` );
    this.speedButton = $( `<div 
                                class="audio-player__speed-button" 
                                title="Playback Speed" 
                                data-speed-index="2"
                                >1x</div>` );
    bottomButtonsWrapper.append( this.downloadButton, this.speedButton );
    audioWrapper.append( bottomButtonsWrapper );
  }

  playAudio() {
    this.audio.play();
    this.playButton.attr( 'action', 'pause' );
  }

  pauseAudio() {
    this.audio.pause();
    this.playButton.attr( 'action', 'play' );
  }

  endAudio() {
    this.playButton.attr( 'action', 'replay' );
  }

  playButtonClickHandler() {
    this.playButton.on( 'click', () => {
      const action = this.playButton.attr( 'action' );
      switch ( action ) {
      case 'replay':
        this.audio.currentTime = 0;
        // eslint-disable-next-line no-fallthrough
      case 'play':
        this.playAudio();
        break;
      case 'pause':
        this.pauseAudio();
        break;
      }
    } );
  }

  seekHandler() {
    this.progressBar.on( 'click', e => {
      const width = parseFloat( this.progressBar.css( 'width' ) );
      const position = e.offsetX;
      let time = ( position / width ) * this.audio.duration;
      
      if ( time < 0 ) time = 0;
      else if ( time > this.audio.duration ) time = this.audio.duration;
      this.audio.currentTime = time;
      this.playAudio();
    } );
  }

  playbackSpeedClickHandler() {
    this.speedButton.on( 'click', () => {
      const currentIndex = parseInt( this.speedButton.data( 'speed-index' ) ) + 1;
      const nextIndex = currentIndex === this.playbackSpeeds.length ? 0 : currentIndex;
      this.speedButton.data( 'speed-index', nextIndex );
      this.audio.playbackRate = this.playbackSpeeds[nextIndex];
      this.speedButton.html( this.playbackSpeeds[nextIndex] + 'x' );
    } );
  }

  volumeBarHandler() {
    this.volumeBar.on( 'click', e => {
      const height = parseFloat( this.volumeBar.css( 'height' ) );
      const position = height - e.offsetY;
      let volume = position / height;
      if ( volume < 0 ) volume = 0;
      else if ( volume > 1 ) volume = 1;
      this.audio.volume = volume;
    } );
  }

  updateProgressBar() {
    const currentTime = this.audio.currentTime;
    const duration = this.audio.duration;
    const progressPercent = ( currentTime / duration ) * 100;
    this.progressBarInner.css( 'width', `${progressPercent}%` );
    if ( currentTime >= duration ) {
      this.endAudio();
    }
  }

  updateVolumeBar() {
    const volumePercent = this.audio.volume * 100;
    this.volumeBarInner.css( 'height', `${volumePercent}%` );
    if ( this.audio.volume > 0 ) {
      this.volumeButton.removeClass( 'muted' );
    } else {
      this.volumeButton.addClass( 'muted' );
    }
  }

  volumeButtonHandler() {
    this.volumeButtonIcon.on( 'click', () => {
      if ( this.audio.volume === 0 ) {
        this.audio.volume = 1;
        this.volumeButton.removeClass( 'muted' );
      } else {
        this.audio.volume = 0;
        this.volumeButton.addClass( 'muted' );
      }
    } );
  }

  init() {
    this.createDom();
    this.playButtonClickHandler();
    this.seekHandler();
    this.volumeBarHandler();
    this.playbackSpeedClickHandler();
    this.volumeButtonHandler();
    this.audio.addEventListener( 'timeupdate', () => {
      this.updateProgressBar();
    } );
    this.audio.addEventListener( 'volumechange', () => {
      this.updateVolumeBar();
    } );
  }
}

$( $ => {
  $( '.bs-div--blog-inner-audio-player' ).each( ( i, wrapperDiv ) => {
    const pressInnerAudioPlayer = new PressInnerAudioPlayer( $( wrapperDiv ) );
    pressInnerAudioPlayer.init();
  } );
} );

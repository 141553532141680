( ( $ ) => {

  const pastEventDiv = $( '.bs-div--past-events-posts' );
  const posts = pastEventDiv.find( '.bs-post' );

  posts.each( ( i, ele ) => {
    const item = $( ele );
    const postCategory = item.find( '.bs-post__category' );
    const postDate = item.find( '.bs-post-event_date' );
    if ( postCategory.length > 0 && postDate.length > 0 ) {
      postCategory.add( postDate ).wrapAll( '<div class="bs-post__wrapper"></div>' );
    }
  } );

} )( jQuery );
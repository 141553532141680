( ( $ ) => {
  const updateProgressBar = () => {
    const scrollPos = $( window ).scrollTop();
    const windowHeight = $( window ).height();
    const documentHeight = $( '.bs-section--blog-inner-banner' ).outerHeight()
      + $( '.bs-section--blog-inner-progress-content' ).outerHeight();
    const progress = ( scrollPos / ( documentHeight - windowHeight ) ) * 100;

    // Update the progress bar width
    $( '.bs-section--blog-inner-progress .container-fluid' ).css( 'width', progress + '%' );
  };
  updateProgressBar();
  $( window ).on( 'scroll', function () {
    updateProgressBar();
  } );
} )( jQuery );